<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}กลุ่มสิทธิ์</h2>
        <v-form v-model="isFormValid">         
          <v-card class="mt-4">
              <v-card-title>กลุ่มสิทธิ์</v-card-title>

              <div class="pa-8">
                <v-form @submit.prevent="submitForm" v-model="isFormValid">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formData.name"
                        outlined
                        label="ชื่อกลุ่ม"                               
                        :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                        hide-details="auto"          
                        dense  
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <td>
                              ชื่อสิทธิ์
                            </td>
                            <td>สิทธิ์</td>
                          </tr>
                        </thead>
                        <tbody>        
                          <tr
                            v-for="(ability, index) in abilities"
                            :key="ability.subject"
                          >
                            <td>{{ converters.convertPageToText(ability.subject) }}</td>
                            <td>
                              <v-checkbox
                              v-for="(action, p_index) in ability.actions"
                              :label="converters.convertPermissionToText(action)"                       
                              :key="ability.subject + '_' + action"
                              :value="action"
                              v-model="formData.ability[ability.subject]"
                              ></v-checkbox>
                            </td>
                        </tr>                   
                        </tbody>
                      </v-simple-table>                                    
                    </v-col>
                  </v-row>       
                </v-form>                       
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.go(-1)"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { convertPageToText, convertPermissionToText } from '@/helpers/converter'
import { maxLengthValidator, required } from '@core/utils/validation'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      abilities: [
        {
          subject: 'Dashboard',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'OnlineOrder',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'PosOrder',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'BuyOrder',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Product',
          actions: ['read', 'update', 'delete', 'cost'],
        },
        {
          subject: 'Inventory',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Customer',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Report',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Document',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Employee',
          actions: ['read', 'update', 'delete'],
        },
        {
          subject: 'Setting',
          actions: ['read', 'update', 'delete'],
        },
      ],
      formData: {
        name: null,
        ability: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
      converters: {
        convertPageToText,
        convertPermissionToText,
      },
    }
  },
  watch: {
    'formData.page': function (value) {
      if (!value) return

      // if (this.formData.name) return

      const { name } = value

      this.formData.name = name
    },
  },
  async created() {
    const role_id = this.$route.params.role_id

    const newAbility = {}
    this.abilities.forEach(item => {
      newAbility[item.subject] = item.actions
    })

    this.formData.ability = newAbility

    if (role_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Employee'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/employee/role/' + role_id)

      this.convertDataFromServerToForm(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const role_id = this.$route.params.role_id

        if (role_id == 'create') await asyncPostAsJson('/employee/role', this.formData)
        else {
          await asyncPostAsJson('/employee/role/' + role_id, { ...this.formData, _method: 'PUT' })
        }

        this.$router.push({ name: 'role' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
    convertDataFromServerToForm({ id, name, ability }) {
      this.formData.id = id
      this.formData.name = name

      const jsonAbility = JSON.parse(ability)

      const newAbility = this.formData.ability

      for (const [key, value] of Object.entries(newAbility)) {
        newAbility[key] = []
      }

      jsonAbility.forEach(element => {
        if (!newAbility[element.subject]) return

        newAbility[element.subject].push(element.action)
      })
    },
  },
  components: {},
}
</script>